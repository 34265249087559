@import 'partials/stacktable';
@import 'partials/variables';

html {
	overflow-y: scroll;
}

body	{
	background: #bbb;
	padding: 0;
	margin: 0;
	text-align: center;
	font-family: Arial;
	color: #666;
	font-size: 13px;
}

body *{
	box-sizing: border-box;
}

img {
	border: none;
	display: inline-block;
	max-width: 100%;
	vertical-align: middle;
}

.page {	
	background: #fff;
	max-width: 1200px;
	margin: 30px auto;
	overflow: hidden;
	text-align: center; 
}

.header {
	box-shadow: 0 0 45px rgba(0,0,0,.15);
	position: relative;
	left: 0px;
	width: 100%;
	padding: 20px 50px 20px 50px;
	z-index: 1;
	.logo{
		margin-right: 30px;
	}
	.documents{
	}
}

.main {
	position: relative;
	top: 0px;
	left: 0px;
	text-align: center;
	margin: 60px auto 60px;
}

table.tabelkaszara{
	border-left: 1px solid #D0D0D0;
	border-top: 1px solid #D0D0D0;
	margin: 0 auto;
}
table.tabelkaszara td{
	border-bottom: 1px solid #D0D0D0;
	border-right: 1px solid #D0D0D0;
	padding: 5px;
	overflow: hidden;
	position: relative;
	text-align:center;
	&:hover{
		span{
			opacity: 1;
			margin-top: 0px;
		}
	}
	span{
		background: $green;
		border-box: content-box;
		color: #fff;
		display: block;
		height: 100%;
		margin-left: -5px;
		//margin-top: 10px;
		opacity: 0;
		padding: 15px;
		position: absolute;
		top: 0;
		transition: all ease-in-out .45s;
		width: 100%;
	}
}
table.tabelkaszara th{
	border-bottom: 1px solid #D0D0D0;
	border-right: 1px solid #D0D0D0;
	padding: 3px;background: #eFeFeF;

}

table.tabelkaszara caption{
	border: 1px solid #D0D0D0;
	border-bottom: none;
	padding: 5px;
	font-size: 12px;
}

.tile{
	color: $green;
}

@media screen and (max-width: 450px){
	.page {	
		margin: 0;
	}
	.logo{
		clear: both;
		margin-right: 0;
	}
	.main {
		margin: 30px auto;
	}
}