/* line 1, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.stacktable {
  width: 100%; }

/* line 2, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.st-head-row {
  padding-top: 1em; }

/* line 3, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.st-head-row.st-head-row-main {
  font-size: 1.5em;
  padding-top: 0; }

/* line 4, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.st-key {
  width: 49%;
  text-align: right;
  padding-right: 1%; }

/* line 5, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.st-val {
  width: 49%;
  padding-left: 1%; }

/* RESPONSIVE EXAMPLE */
/* line 11, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.stacktable.large-only {
  display: table; }

/* line 12, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
.stacktable.small-only {
  display: none; }

@media (max-width: 800px) {
  /* line 15, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
  .stacktable.large-only {
    display: none; }
  /* line 16, C:/xampp/htdocs/stoneco_docs/public/assets/scss/partials/_stacktable.scss */
  .stacktable.small-only {
    display: table; } }

/* line 4, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
html {
  overflow-y: scroll; }

/* line 8, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
body {
  background: #bbb;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: Arial;
  color: #666;
  font-size: 13px; }

/* line 18, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
body * {
  box-sizing: border-box; }

/* line 22, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
img {
  border: none;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle; }

/* line 29, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
.page {
  background: #fff;
  max-width: 1200px;
  margin: 30px auto;
  overflow: hidden;
  text-align: center; }

/* line 37, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
.header {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.15);
  position: relative;
  left: 0px;
  width: 100%;
  padding: 20px 50px 20px 50px;
  z-index: 1; }
  /* line 44, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  .header .logo {
    margin-right: 30px; }

/* line 51, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
.main {
  position: relative;
  top: 0px;
  left: 0px;
  text-align: center;
  margin: 60px auto 60px; }

/* line 59, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
table.tabelkaszara {
  border-left: 1px solid #D0D0D0;
  border-top: 1px solid #D0D0D0;
  margin: 0 auto; }

/* line 64, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
table.tabelkaszara td {
  border-bottom: 1px solid #D0D0D0;
  border-right: 1px solid #D0D0D0;
  padding: 5px;
  overflow: hidden;
  position: relative;
  text-align: center; }
  /* line 72, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  table.tabelkaszara td:hover span {
    opacity: 1;
    margin-top: 0px; }
  /* line 77, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  table.tabelkaszara td span {
    background: #90c251;
    border-box: content-box;
    color: #fff;
    display: block;
    height: 100%;
    margin-left: -5px;
    opacity: 0;
    padding: 15px;
    position: absolute;
    top: 0;
    transition: all ease-in-out .45s;
    width: 100%; }

/* line 93, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
table.tabelkaszara th {
  border-bottom: 1px solid #D0D0D0;
  border-right: 1px solid #D0D0D0;
  padding: 3px;
  background: #eFeFeF; }

/* line 100, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
table.tabelkaszara caption {
  border: 1px solid #D0D0D0;
  border-bottom: none;
  padding: 5px;
  font-size: 12px; }

/* line 107, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
.tile {
  color: #90c251; }

@media screen and (max-width: 450px) {
  /* line 112, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  .page {
    margin: 0; }
  /* line 115, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  .logo {
    clear: both;
    margin-right: 0; }
  /* line 119, C:/xampp/htdocs/stoneco_docs/public/assets/scss/style.scss */
  .main {
    margin: 30px auto; } }
